import revive_payload_client_lA5So6OFy9 from "/home/ploi/www.fara-kezmarok.sk/node_modules/.pnpm/nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_idb-keyval@6.2_7rpltl6isw4p6ra4mvx7okknri/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_SO580XdIZC from "/home/ploi/www.fara-kezmarok.sk/node_modules/.pnpm/nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_idb-keyval@6.2_7rpltl6isw4p6ra4mvx7okknri/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_oKGtAefxxE from "/home/ploi/www.fara-kezmarok.sk/node_modules/.pnpm/nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_idb-keyval@6.2_7rpltl6isw4p6ra4mvx7okknri/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_iRYsOvwAQN from "/home/ploi/www.fara-kezmarok.sk/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.4_rollup@4.18.0_vite@5.3.5_@types+node@20.14.9_sass@1.77_hp2iliw3fry55mjpab2fa675t4/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_BuNlfxCiZM from "/home/ploi/www.fara-kezmarok.sk/node_modules/.pnpm/nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_idb-keyval@6.2_7rpltl6isw4p6ra4mvx7okknri/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_NGOURL0qp6 from "/home/ploi/www.fara-kezmarok.sk/node_modules/.pnpm/nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_idb-keyval@6.2_7rpltl6isw4p6ra4mvx7okknri/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_0tDKgiFGv4 from "/home/ploi/www.fara-kezmarok.sk/node_modules/.pnpm/nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_idb-keyval@6.2_7rpltl6isw4p6ra4mvx7okknri/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_8n5JQn71U5 from "/home/ploi/www.fara-kezmarok.sk/node_modules/.pnpm/nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_idb-keyval@6.2_7rpltl6isw4p6ra4mvx7okknri/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/ploi/www.fara-kezmarok.sk/.nuxt/components.plugin.mjs";
import prefetch_client_icijK0uVeV from "/home/ploi/www.fara-kezmarok.sk/node_modules/.pnpm/nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_idb-keyval@6.2_7rpltl6isw4p6ra4mvx7okknri/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/home/ploi/www.fara-kezmarok.sk/.nuxt/pwa-icons-plugin.ts";
import pwa_client_7TRquUnhwk from "/home/ploi/www.fara-kezmarok.sk/node_modules/.pnpm/@vite-pwa+nuxt@0.10.0_magicast@0.3.4_rollup@4.18.0_vite@5.3.5_@types+node@20.14.9_sass@1.77.8_ulxc7eriib3dxa6mazmjxxjn44/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import sentry_client_shVUlIjFLk from "/home/ploi/www.fara-kezmarok.sk/plugins/sentry.client.ts";
export default [
  revive_payload_client_lA5So6OFy9,
  unhead_SO580XdIZC,
  router_oKGtAefxxE,
  _0_siteConfig_iRYsOvwAQN,
  payload_client_BuNlfxCiZM,
  navigation_repaint_client_NGOURL0qp6,
  check_outdated_build_client_0tDKgiFGv4,
  chunk_reload_client_8n5JQn71U5,
  components_plugin_KR1HBZs4kY,
  prefetch_client_icijK0uVeV,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_7TRquUnhwk,
  sentry_client_shVUlIjFLk
]