import { default as _91_46_46_46slug_93AyxtcFBE9vMeta } from "/home/ploi/www.fara-kezmarok.sk/pages/[[category]]/[...slug].vue?macro=true";
import { default as blogZ3UN9JbnDPMeta } from "/home/ploi/www.fara-kezmarok.sk/pages/blog.vue?macro=true";
import { default as farske_45oznamy5cZjdtzc8rMeta } from "/home/ploi/www.fara-kezmarok.sk/pages/farske-oznamy.vue?macro=true";
import { default as indexCqKVyrLZWxMeta } from "/home/ploi/www.fara-kezmarok.sk/pages/index.vue?macro=true";
import { default as _91name_93GRHmeTrvljMeta } from "/home/ploi/www.fara-kezmarok.sk/pages/kategoria/[name].vue?macro=true";
import { default as nazivoxOckKOAvEWMeta } from "/home/ploi/www.fara-kezmarok.sk/pages/nazivo.vue?macro=true";
import { default as sluzba_45v_45kancelariilFuf659q7GMeta } from "/home/ploi/www.fara-kezmarok.sk/pages/sluzba-v-kancelarii.vue?macro=true";
export default [
  {
    name: "category-slug",
    path: "/:category?/:slug(.*)*",
    component: () => import("/home/ploi/www.fara-kezmarok.sk/pages/[[category]]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/home/ploi/www.fara-kezmarok.sk/pages/blog.vue").then(m => m.default || m)
  },
  {
    name: "farske-oznamy",
    path: "/farske-oznamy",
    component: () => import("/home/ploi/www.fara-kezmarok.sk/pages/farske-oznamy.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/ploi/www.fara-kezmarok.sk/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "kategoria-name",
    path: "/kategoria/:name()",
    component: () => import("/home/ploi/www.fara-kezmarok.sk/pages/kategoria/[name].vue").then(m => m.default || m)
  },
  {
    name: "nazivo",
    path: "/nazivo",
    component: () => import("/home/ploi/www.fara-kezmarok.sk/pages/nazivo.vue").then(m => m.default || m)
  },
  {
    name: "sluzba-v-kancelarii",
    path: "/sluzba-v-kancelarii",
    component: () => import("/home/ploi/www.fara-kezmarok.sk/pages/sluzba-v-kancelarii.vue").then(m => m.default || m)
  }
]