import validate from "/home/ploi/www.fara-kezmarok.sk/node_modules/.pnpm/nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_idb-keyval@6.2_7rpltl6isw4p6ra4mvx7okknri/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45parish_45route_45allowance_45global from "/home/ploi/www.fara-kezmarok.sk/middleware/01.parishRouteAllowance.global.ts";
import _02_45remove_45trailing_45slash_45global from "/home/ploi/www.fara-kezmarok.sk/middleware/02.removeTrailingSlash.global.ts";
import _03_45refresh_45content_45global from "/home/ploi/www.fara-kezmarok.sk/middleware/03.refreshContent.global.ts";
import _04_45init_45parish_45add_45seo_45tags_45global from "/home/ploi/www.fara-kezmarok.sk/middleware/04.initParishAddSeoTags.global.ts";
import manifest_45route_45rule from "/home/ploi/www.fara-kezmarok.sk/node_modules/.pnpm/nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_idb-keyval@6.2_7rpltl6isw4p6ra4mvx7okknri/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45parish_45route_45allowance_45global,
  _02_45remove_45trailing_45slash_45global,
  _03_45refresh_45content_45global,
  _04_45init_45parish_45add_45seo_45tags_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}